//1.FONT
@font-face {
    font-family: "uicons-regular-rounded";
    src: url("../fonts/uicons/uicons-regular-rounded.eot#iefix") format("embedded-opentype"), 
    url("../fonts/uicons/uicons-regular-rounded.woff2") format("woff2"), 
    url("../fonts/uicons/uicons-regular-rounded.woff") format("woff");
}
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@400;700&family=Noto+Sans:wght@400;700&display=swap');
$font-heading: "Noto Sans", sans-serif;
$font-text: "Chivo", sans-serif;
$font-3xs: 10px;
$font-xxs: 12px;
$font-xs: 13px;
$font-sm: 14px;
$font-md: 16px;
$font-md-2: 15px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 22px;
$font-3xl: 58px;

//2.COLOR
// New
$color-gray-600: #475467;
$color-gray-900: #101828;
$color-green-900: #006D77;
$color-green-500: #83C5BE;
$color-green-100: #AFEAE4;
$color-orange-900: #E29578;
$color-orange-500: #FFDDD2;
$color-orange-100: #FAEDE3;
$color-primary: #508FDA;
$color-secondary: #8D99AE;
$color-success: #06D6A0;
$color-danger: #EF476F;
$color-warning: #FFD166;
$color-info: #38A0C2;
$color-white: #ffffff;
$color-gray-50: #F9FAFB;
$color-gray-100: #F2F4F7;
$color-gray-200: #E4E7EC;
$color-gray-300: #D0D5DD;
$color-gray-400: #98A2B3;
$color-gray-500: #667085;
$color-gray-600: #475467;
$color-gray-700: #344054;
$color-gray-800: #1D2939;
$color-gray-900: #101828;
$color-gray-1000: #1F2938;
$color-gray-1100: #7E7E7E;
$color-gray-1200: #9A9A9A;
$color-gray: D1DAE4;
// End New

//3.Boxshadow
// New
$box-shadow-1: 14px 14px 36px rgba(153, 153, 153, 0.22);
$box-shadow-2: 0px 45px 80px rgba(0, 0, 0, 0.04);
$box-shadow-3: 0px 47px 65px rgba(21, 28, 38, 0.1);
$box-shadow-4: 4px 6px 23px rgba(228, 92, 35, 0.1);
$box-shadow-5: 4px 4px 40px rgba(16,24,40,0.1);
$box-shadow-inner: inset 0px 40px 80px #FCFCFC;
// End new


//4. Border
$border-clor-1: #061224;
$border-color-2: rgba(6, 18, 36, 0.1);
//5. Background
// New
$background-white: #ffffff;
$background-gray-100: #F2F4F7;
$background-gray-900: #101828;
$color-green-1000: #004B52;
$background-gray-900-50: rgba(16, 24, 40, 0.5);
$background-bg-1: #EAE4E9;
$background-bg-2: #FFF3EA;
$background-bg-2-1: #fff3eacc;
$background-bg-3: #FDE2E4;
$background-bg-4: #FAD2E1;
$background-bg-5: #DBECE5;
$background-bg-6: #BEE1E6;
$background-bg-6-2: #bee1e64d;
$background-bg-7: #F0EFEB;
$background-bg-8: #DFE7FD;
$background-bg-9: #D1ECFD;
$background-bg-10: #DDD3FA;
$background-bg-11: rgba(11, 123, 63, 0.2);
$background-bg-12: #158E99;
// End new

// 6. text-shadow
$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
// 7. breakpoints
$grid-breakpoints: (
    xs: 0,
    xsm: 450px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
